import Card from 'antd/es/card/Card';
import ReactApexChart from 'react-apexcharts';
import Title from 'antd/es/typography/Title';
import { Divider, Empty, Typography } from 'antd';

const RiskBusinessImpact = ({ data = [], categories = [], total = {} }: { data: any, categories: any, total: any }) => {
  if (total && total.grand_total === 0) {
    return <Card>
      <Typography.Title level={4}>
        Risk Business Impact
      </Typography.Title>
      <Empty description="No risk data available" />
    </Card>;
  }
  return (
    <Card>
      {/* {total} */}
      {data.length === 0 && <div>
        <Empty description="No risk data available" />
      </div>}
      <div>
        <div className="">
          <Typography.Title level={4}>
            Risk Business Impact
          </Typography.Title>
          <Typography.Paragraph>
            This chart shows the number of vulnerabilities in each business impact category.
          </Typography.Paragraph>
        </div>

        <div className="flex gap-4 mt-4 h-full">
          <div className="w-full xl:w-8/12">
            <ReactApexChart
              series={data}
              options={{
                chart: {
                  toolbar: { show: false },
                  type: 'bar',
                  height: 300,
                  stacked: false
                },
                colors: ['#D32F2F', '#F57C00', '#FBC02D', '#388E3C'],
                plotOptions: {
                  bar: {
                    borderRadius: 2,
                    borderRadiusApplication: 'end',
                    borderRadiusWhenStacked: 'last',
                    hideZeroBarsWhenGrouped: true,
                    barHeight: 55,
                    horizontal: false,
                    columnWidth: '50%'
                  }
                },
                dataLabels: {
                  enabled: false
                },
                legend: {
                  show: true,
                  position: 'bottom',
                  horizontalAlign: 'left',
                  markers: {
                    shape: 'line'
                  }
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: categories,
                  title: {
                    text: 'Business Impact'
                  },
                  axisTicks: {
                    show: false
                  }
                },
                yaxis: {
                  title: {
                    text: 'Number of Vulnerabilities'
                  }
                },
                fill: {
                  opacity: 60
                }
              }}
              type="bar"
              height={300}
            />
          </div>
          <div className="h-[300px]">
            <Divider type="vertical" className='h-full' />
          </div>
          <div className='w-full xl:w-4/12 h-full'>
            Total Business Impact <br />
            <Title level={5}>{total.grand_total} </Title>
            <div className="">
              <ReactApexChart
                series={[{
                  data: total.data
                }]}
                options={{
                  chart: {
                    toolbar: { show: false },
                    type: 'bar',
                    height: 150
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 2,
                      borderRadiusApplication: 'around',
                      borderRadiusWhenStacked: 'all',
                      hideZeroBarsWhenGrouped: true,
                      horizontal: true,
                      barHeight: 15,
                      isDumbbell: false,
                      isFunnel: false,
                      isFunnel3d: true,
                      colors: {
                        backgroundBarOpacity: 0.7
                      },
                      dataLabels: {
                        total: {
                          offsetX: 0,
                          enabled: true,
                          style: {
                            color: '#373d3f',
                            fontSize: '12px',
                            fontWeight: 600
                          }
                        }
                      }
                    }
                  },
                  legend: {
                    show: false
                  },
                  colors: ['#007A80'],
                  dataLabels: {
                    enabled: true,
                    style: {
                      fontWeight: 700,
                      fontSize: '12px'
                    },
                    dropShadow: {
                      blur: 0
                    }
                  },
                  grid: {
                    show: false,
                    padding: {
                      right: 0,
                      left: 0
                    }
                  },
                  stroke: {
                    show: false,
                    width: 3,
                    fill: {
                      type: 'solid',
                      opacity: 0.85
                    }
                  },
                  xaxis: {
                    categories: total.categories || [],
                    labels: {
                      show: false,
                      trim: false
                    },
                    axisTicks: {
                      show: false
                    },
                    axisBorder: {
                      show: false
                    }
                  },
                  yaxis: {
                    show: true,
                    tickAmount: 8
                  }
                }}
                type='bar'
                height={200}
              />
            </div>
          </div>
        </div>

      </div>
    </Card>
  );
};
export default RiskBusinessImpact;