import { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DefaultLayout from './components/layout/DefaultLayout';
import routes from './routes';
import { Provider } from 'react-redux'; // Only import Provider here
import store from './redux/store'; // Import the Redux store
import { ThemeProvider } from '@material-tailwind/react';
import Dashboard from './pages/dashboard/Dashboard';
import LoginPage from './pages/login/LoginPage';
import Loading from './components/UI/loading/Loading';
import NotFoundPage from './pages/404/404Page';
import { App as AntdApp } from 'antd'; // Import the App wrapper
import { ProtectedRoute } from './routes/ProtectedRoute';

function AppContent() {
  // const [loading, setLoading] = useState<boolean>(true);
  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 500); // Remove loading screen
  // }, []);
  // if (loading) return <Loading />;

  return (
    <ThemeProvider>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<DefaultLayout />}>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Route>
        {routes.map((route, index) => {
          const { path, component: Component } = route;
          return (
            <Route element={<DefaultLayout />} key={index}>
              <Route
                key={index}
                path={path}
                element={
                  <ProtectedRoute>
                    <Suspense fallback="loading">
                      <Component />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
            </Route>
          );
        })}
        {/* Catch-all Route for 404 Not Found */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <AntdApp>
        <AppContent />
      </AntdApp>
    </Provider>
  );
}

export default App;
