import * as actionTypes from './types';
import * as authService from '../../service/auth';
import { getAccountFromToken } from '../../service/auth/jwt.service';
import { setupTokenRefresh } from '../../service/api';
export const login =
  ({ email, password }) =>
    async (dispatch) => {
      console.log('Dispatching login action...');
      dispatch({
        type: actionTypes.REQUEST_LOADING
      });
      try {
        const { status, data } = await authService.login({ email, password });

        if (status === 200) {
          console.log('Login successful. Setting up token refresh...');
          data.userName = getAccountFromToken(data.access_token).preferred_username;
          const auth_state = {
            current: data,
            isLoggedIn: true,
            isLoading: false,
            isSuccess: true
          };
          window.localStorage.setItem('auth', JSON.stringify(auth_state));
          window.localStorage.removeItem('isLogout');

          setupTokenRefresh();

          dispatch({
            type: actionTypes.REQUEST_SUCCESS,
            payload: data
          });
        } else {
          console.error('Login failed. Status:', status);
          dispatch({
            type: actionTypes.REQUEST_FAILED
          });
        }
      } catch (error) {
        console.error('Error during login:', error);
        dispatch({
          type: actionTypes.REQUEST_FAILED
        });
      }
    };

let isLoggingOut = false;
let logoutMessageDisplayed = false; // Prevent duplicate notifications

export const logout = () => async (dispatch) => {
  try {
    await authService.logout(); // Optional: API call to log out
    await localStorage.removeItem('auth');
    dispatch({
      type: actionTypes.LOGOUT_SUCCESS
    });
  } catch (error) {
    console.error('Error during logout:', error);
    dispatch({
      type: actionTypes.LOGOUT_FAILED
    });
  }
};

