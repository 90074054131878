import axios from 'axios';
import { AUTH_BASE_URL } from '../config/serverApiConfig';
import { notification, message } from 'antd'; // Import message
import { setupCache } from 'axios-cache-interceptor';

// Function to get the subdomain (tenant)
function getSubdomain() {
  const hostname = window.location.hostname; // e.g., secure1.seeward.io
  // Extract subdomain, e.g., "secure1"
  return hostname.split('.')[0];
}

// Function to handle login redirection
let logoutMessageDisplayed = false; // Prevent duplicate notifications

function redirectLogin() {
  console.log('Redirecting to login due to session expiration...');
  if (tokenRefreshTimeout) clearTimeout(tokenRefreshTimeout);
  window.localStorage.removeItem('auth');
  window.localStorage.removeItem('isLogout');

  const currentPath = window.location.pathname;
  window.localStorage.setItem('lastVisitedPath', currentPath);
  if (!window.location.href.includes('/login')) {
    window.location.href = '/login';
  }
  message.destroy();
}

// Function to handle the refresh token process
async function refreshAccessToken() {
  const user = localStorage.getItem('auth');
  const tmpAuth = user ? JSON.parse(user).current : null;

  if (!tmpAuth || !tmpAuth.refresh_token) {
    console.error('No refresh token available. Redirecting to login...');
    await message.destroy();
    await notification.info({
      message: 'Session Expired',
      description: 'Your session has expired. Please log in again to continue.'
    });
    redirectLogin();
    throw new Error('No refresh token available');
  }
  try {
    const response = await axios.post(
      `${AUTH_BASE_URL}refresh_token`,
      { refresh_token: tmpAuth.refresh_token },
      {
        headers: {
          'Authorization': `Bearer ${tmpAuth.refresh_token}`,
          'X-Tenant-ID': getSubdomain() // Pass the subdomain as the tenant identifier
        }
      }
    );

    const { access_token, refresh_token } = response.data;
    const updatedTokens = {
      ...tmpAuth,
      access_token,
      refresh_token: refresh_token ? refresh_token : tmpAuth.refresh_token
    };
    localStorage.setItem('auth', JSON.stringify({ current: updatedTokens }));

    return access_token;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    redirectLogin();
    throw error;
  }
}

// Create and configure axios instance
export const api = () => {
  const user = localStorage.getItem('auth');
  const tmpAuth = user ? JSON.parse(user).current : {};

  // Get the subdomain to identify the tenant
  const tenantSubdomain = getSubdomain();

  const instance = axios.create({
    headers: {
      'Authorization': `Bearer ${tmpAuth.access_token}`,
      'X-Tenant-ID': tenantSubdomain // Add tenant identifier in all API requests
    }
  });

  // Setup axios cache interceptor
  const cache = setupCache(instance, {});

  // Interceptor to handle token refresh
  cache.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newAccessToken = await refreshAccessToken();
          originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
          originalRequest.headers['X-Tenant-ID'] = tenantSubdomain; // Ensure tenant header is retained
          return cache(originalRequest);
        } catch (refreshError) {
          console.error('Failed to refresh token:', refreshError);
          redirectLogin();
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

let tokenRefreshTimeout: NodeJS.Timeout | null = null;

// Function to parse JWT and get its expiry time
function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedPayload = JSON.parse(atob(base64));
  return decodedPayload.exp * 1000; // Convert expiry to milliseconds
}

export function setupTokenRefresh() {
  const user = localStorage.getItem('auth');
  const tmpAuth = user ? JSON.parse(user).current : null;

  if (tmpAuth && tmpAuth.access_token) {
    const tokenExpiry = parseJwt(tmpAuth.access_token);
    const refreshTime = tokenExpiry - Date.now() - 60 * 1000; // Refresh 1 min before expiry

    if (refreshTime > 0) {
      tokenRefreshTimeout = setTimeout(async () => {
        try {
          await refreshAccessToken();
          setupTokenRefresh(); // Schedule the next refresh
        } catch (err) {
          console.error('Token refresh failed:', err);
          redirectLogin();
        }
      }, refreshTime);
    } else {
      redirectLogin();
    }
  }
}

// Call `setupTokenRefresh` after login or app initialization
function initializeAuth() {
  const user = localStorage.getItem('auth');
  if (user) {
    setupTokenRefresh();
  }
}
initializeAuth();
