import { Link } from 'react-router-dom';
import DropdownNotification from './DropdownNotification';
import DropdownUser from './DropdownUser';
import { XMarkIcon } from '@heroicons/react/24/outline';
import LogoImg from '../../../assets/image/logoSeeWard.png';
import { MenuFoldOutlined } from '@ant-design/icons';
import Breadcrumb from '../breadcrumb/Breadcrumbs';

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => (
  <header
    // className="sticky top  -0 z-999 flex w-full bg-white drop-shadow-none shadow-none dark:bg-boxdark dark:drop-shadow-none"
  >
    <div
      // className="flex flex-grow items-center justify-between py-2 px-4 shadow-1 md:px-6 2xl:px-11"
    >
      <div>
        {/* <Breadcrumb
          withLabel={false}
          showBreadcrumb
        /> */}
      </div>

      <div className="flex items-center gap-3 2xsm:gap-7">
        <ul className="flex items-center gap-2 2xsm:gap-4">
          {/* <!-- Dark Mode Toggler --> */}

          {/* <!-- Notification Menu Area --> */}
          {/* <DropdownNotification /> */}
          {/* <!-- Notification Menu Area --> */}

          {/* <!-- Chat Notification Area --> */}
        </ul>

        {/* <!-- User Area --> */}
        {/* <!-- User Area --> */}
      </div>
    </div>
  </header>
);

export default Header;
