import {
  Skeleton,
  TableColumnsType,
  TableColumnType,
  Tag
} from 'antd';
import RiskScoreTag from '../../utils/RiskScoreTag';
import { TOP_RISK_VULN_PROPS } from './type';
import Link from 'antd/es/typography/Link';

export const TopVulsColumns: TableColumnsType<TOP_RISK_VULN_PROPS> = [
  {
    title: 'Risk',
    dataIndex: 'risk',
    key: 'risk',
    width: 50,
    render: (text: any) => <RiskScoreTag risk={text} />
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  }
];
export const TopAssetColumns: TableColumnsType<TOP_RISK_VULN_PROPS> = [
  {
    title: 'AVS',
    dataIndex: 'avs',
    key: 'avs',
    width: 50,
    render: (text: any) => <RiskScoreTag risk={text} />
  }, {
    title: 'Asset',
    dataIndex: 'asset',
    key: 'asset',
    render: (text: any, record) => record.hostname ? record.hostname : text
  }
];
export const MostVulnsProjectColumns: TableColumnsType<TOP_RISK_VULN_PROPS> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 20,
    render: (text: any) => <RiskScoreTag risk={text} />
  }, {
    title: 'Risk Score',
    dataIndex: 'risk',
    key: 'risk',
    width: 20,
    render: (text: any) => <RiskScoreTag risk={text} />
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  }
];

export const overviewColumns: any = [
  {
    title: 'OS',
    width: '25%',
    dataIndex: 'category',
    align: 'left',
    sorter: (a: any, b: any) => a.category.localeCompare(b.category),
    render: (value: string) => <div
      className="text-[#2b7db8] font-semibold"
    >{value}</div>
  }, {
    title: 'Vulnerability',
    width: '25%',
    dataIndex: 'vuln_count',
    sorter: (a: any, b: any) => a.vuln_count - b.vuln_count,
    align: 'right'
  }, {
    title: 'Non-compl. Vulns',
    width: '25%',
    dataIndex: 'non_compl_vulns',
    sorter: (a: any, b: any) => a.non_compl_vulns - b.non_compl_vulns,
    align: 'right'
  }];

export const overviewColumnsCVE: any = [
  {
    title: 'CVE',
    width: '25%',
    dataIndex: 'name',
    align: 'left',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    render: (value: string) => <div
      className="text-[#2b7db8] font-semibold"
    >{value}</div>
  }, {
    title: 'Vuln Count',
    width: '25%',
    dataIndex: 'count',
    align: 'right',
    sorter: (a: any, b: any) => a.count - b.count,
    render: (value: number) => <div className='text-black'>
      <Tag bordered={false} color="geekblue">
        {value}
      </Tag>
    </div>
  }, {
    title: 'EPSS',
    width: '25%',
    dataIndex: 'epss',
    align: 'right',
    sorter: (a: any, b: any) => a.epss - b.epss,
    render: (value: number) => <div className='text-black'>
      <Tag bordered={false} color="geekblue">
        {(value * 100).toFixed(2)}%
      </Tag>
    </div>
  }];

export const overviewColumnsOS: any = [
  {
    title: 'OS version',
    width: '25%',
    dataIndex: 'name',
    align: 'left',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    render: (value: string) => <div
      className="text-[#2b7db8] font-semibold"
    >{value}</div>
  }, {
    title: 'Unique Hosts',
    width: '25%',
    dataIndex: 'unique_host',
    align: 'right',
    sorter: (a: any, b: any) => a.unique_host - b.unique_host
  }, {
    title: 'Vulnerabilities',
    width: '25%',
    dataIndex: 'total_vuln',
    align: 'right',
    sorter: (a: any, b: any) => a.total_vuln - b.total_vuln
  }];

export const osData = [
  {
    title: 'Total devices',
    count: 200
  }, {
    title: 'Workstations',
    count: 150
  }, {
    title: 'Linux',
    count: 50
  }, {
    title: 'Windows Server',
    count: 30
  }, {
    title: 'other',
    count: 10
  }, {
    title: '',
    count: null
  }
];

export const overviewColumnsApplication: any = [
  {
    title: 'Application',
    dataIndex: 'application',
    sorter: (a: any, b: any) => a.application.localeCompare(b.application),
    render: (value: string) => <div
      className="text-[#2b7db8] font-semibold"
    >{value.replace('_', ' ')}</div>
  }, {
    title: 'Hosts',
    width: '25%',
    dataIndex: 'total_hosts',
    sorter: (a: any, b: any) => a.total_hosts - b.total_hosts,
    align: 'right'
  }, {
    title: 'Vulns',
    width: '25%',
    dataIndex: 'total_vulns',
    sorter: (a: any, b: any) => a.total_vulns - b.total_vulns,
    align: 'right'
  }];

export const vuln_by_user_list: any = [
  {
    title: 'User',
    dataIndex: 'email',
    sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    render: (value: string) => <div
      className="text-[#2b7db8] font-semibold"
    >{value}</div>
  }, {
    title: 'Vulnerabilities',
    width: '25%',
    dataIndex: 'total_vulns',
    sorter: (a: any, b: any) => a.total_vulns - b.total_vulns,
    align: 'right'
  }];