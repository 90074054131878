import React, { useRef, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

// Define types for the data structure
interface TreeNode {
  name: string;
  value?: number;
  children?: TreeNode[];
}

interface TreemapProps {
  data: TreeNode;
}

const TreemapCPE: React.FC<TreemapProps> = ({ data = [] }: any) => (
  <div style={{ width: '100%', height: '100%' }}>
    <ReactApexChart
      series={data}
      options={{
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'left',
          markers: {
            shape: 'line'
          },
          showForSingleSeries: true,
          onItemClick: {
            toggleDataSeries: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (text: any, op: any) {
            return text + ' \n(' + op.value + ')';
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif'
          }
        },
        chart: {
          height: 300,
          type: 'treemap',
          toolbar: {
            show: false
          }
        },
        // colors: [
        //   '#E0E6EB', '#C9D1D3', '#B1BBC0', '#97A5A8', '#808F9C', '#697989', '#546373', '#3F4D5D', '#2A3747', '#132431'
        // ],
        title: {
          align: 'center'
        },
        stroke: {
          lineCap: 'square',
          width: 1,
          dashArray: 0,
          curve: 'linestep'
        },
        plotOptions: {
          treemap: {
            enableShades: true,
            shadeIntensity: 0.2,
            reverseNegativeShade: false,
            distributed: true,
            useFillColorAsStroke: false,
            borderRadius: 3,
            dataLabels: {
              format: 'scale'
            }
          }
        }
      }
      }
      type='treemap'
      height={350}
    />
  </div>
);

export default TreemapCPE;
