import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Tag, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import dashboardService from '../../../../service/dashboard.service';

const CustomApexChartDays = ({ data }: any) => {
  const tagsData = ['All', '1 year', '6 Month', '3 Month', '30 Days'];

  const [selectedTag, setSelectedTag] = useState<string>('30 Days');
  const [chartData, setChartData] = useState<any>(data?.data || []); // Default to the incoming data
  const [chartSeries, setChartSeries] = useState<any>(data?.series || []); // Default to the incoming series
  const [loading, setLoading] = useState<boolean>(!data || data.length === 0); // Initial loading state

  // Simulate API call
  const fetchData = async (tag: string): Promise<any> => {
    console.log(`Fetching data for: ${tag}`);
    setLoading(true);
    const mapper: any = {
      'All': 'all',
      '1 year': 'last_12_months',
      '6 Month': 'last_6_months',
      '3 Month': 'last_3_months',
      '30 Days': 'last_30_days'
    };
    const data = await dashboardService.getVulnerabilitiesByDataDashboard(mapper[tag]);
    console.log(data.data.vulnerabilities_by_date);
    setLoading(false);
    return data.data.vulnerabilities_by_date;
  };

  const handleChange = async (tag: string) => {
    setSelectedTag(tag);
    // Fetch data for other tags
    const response = await fetchData(tag);
    setChartData(response.data || []);
    setChartSeries(response.series || []);
  };

  // Update chart data when the initial `data` prop changes
  useEffect(() => {
    if (data?.data?.length) {
      setChartData(data.data);
      setChartSeries(data.series);
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="chart-container">
      <Flex
        gap={4}
        wrap
        align="center"
        justify="space-between"
        className="mx-4"
      >
        <span className="font-bold">Vulnerabilities by date:</span>
        <div>
          {loading && <Spin indicator={<LoadingOutlined spin />} />}
          {tagsData.map<React.ReactNode>((tag) => (
            <Tag.CheckableTag
              key={tag}
              className="rounded-full"
              checked={selectedTag === tag}
              onChange={() => handleChange(tag)}
            >
              {tag}
            </Tag.CheckableTag>
          ))}
        </div>
      </Flex>

      {(
        <ReactApexChart
          id="bydays"
          series={[
            {
              name: 'Vulns by Day',
              data: chartData
            }
          ]}
          options={{
            chart: {
              stacked: false,
              animations: {
                enabled: true,
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350
                }
              },
              group: 'sparklines',
              background: '',
              foreColor: '#333',
              height: 10,
              toolbar: {
                show: false
              },
              zoom: {
                type: 'x',
                enabled: true
              }
            },
            stroke: {
              width: 2
            },
            xaxis: {
              type: 'datetime',
              tickAmount: 10,
              position: 'bottom',
              categories: chartSeries, // Use the appropriate series
              labels: {
                rotate: -45,
                format: 'dd MMM'
              },
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true
              }
            },
            dataLabels: {
              enabled: false
            },
            yaxis: {
              title: {
                text: 'Total vulns'
              },
              labels: {
                formatter: (value) => value.toFixed(0)
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              }
            },
            markers: {
              size: 0
            },
            tooltip: {
              shared: false,
              x: {
                format: 'dd MMM yyyy'
              }
            }
          }}
          type="area"
          height={240}
        />
      )}
    </div>
  );
};

export default CustomApexChartDays;
