import { AddUserProps } from '../components/UI/tab/types';
import { API_BASE_URL, AUTH_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';
import axios, { AxiosError } from 'axios';

export const addUser = async (userData: AddUserProps, files: FileList | null) => {
  try {
    const formData = new FormData();

    const response = await api().post(`${API_BASE_URL}user/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getRoleList = async () => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    const response = await axios.get(
      AUTH_BASE_URL + 'roles/list',
      {
        headers: {
          Authorization: `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data.roles;
  } catch (error) {
    return errorHandler(error);
  }
};

export const createUser = async (userData: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};
    const response = await axios.post(
      AUTH_BASE_URL + 'user',  // Change from GET to POST
      userData,                // Pass the user data in the request body
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data.message;  // Return the success message from the backend
  } catch (error) {
    return errorHandler(error);  // Handle the error
  }
};

export const deleteUsers = async (ids: any[]) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await axios.delete(
      `${AUTH_BASE_URL}users`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        },
        data: {
          ids: ids  // Send the list of IDs in the request body
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );

    return data.message;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

const getList = async ({ page, items, sorting, globalFilter, filter }: { page: number; items: number; sorting: string; globalFilter: string; filter?: any }) => {
  try {

    let url = `${AUTH_BASE_URL}users?page=${page}&total=${items}`;
    if (sorting) {
      url += `&sorting=${JSON.stringify(sorting)}`;
    }
    if (globalFilter) {
      url += `&q=${globalFilter}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }

    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};
    const response = await axios.get(
      url,  // Change from GET to POST
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getUserDetails = async (id: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await axios.get(
      `${AUTH_BASE_URL}user/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data.database_info;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

export const  updateUser = async (id: string, dataItem: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await axios.put(
      `${AUTH_BASE_URL}user/${id}`,
      dataItem,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data.database_info;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

export const  updatePassword = async (id: string, password: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await axios.put(
      `${AUTH_BASE_URL}user/${id}`,
      {
        password
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );

    return data.database_info;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

export default {
  getRoleList,
  getList,
  deleteUsers,
  getUserDetails,
  updateUser
};